import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import SvgChannel from "../../assets/IconComponents/Channel";
import { t } from "i18next";
import ContentTypeSelectorPopup from "./ContentTypeSelectorPopup";
import Tag from "../Tag";
import SvgText from "../../assets/IconComponents/Text";
import SvgPicture from "../../assets/IconComponents/Picture";
import SvgVideoGif from "../../assets/IconComponents/VideoGif";
import SvgContentType from "../../assets/IconComponents/ContentType";

export interface IContentTypeSelectorProps {
  contentTypesSelected: (data: IContentTypeData) => void;
  className?: string;
  closeOnSelect?: boolean;
  selectedContentType?: string;
}

export interface IContentTypeData {
  id: string;
  title: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

const contentTypes: IContentTypeData[] = [
  { id: "1", title: "Text", icon: SvgText },
  { id: "2", title: "Images", icon: SvgPicture },
  { id: "3", title: "Video", icon: SvgVideoGif },
  { id: "4", title: "All", icon: SvgContentType },
];

function ContentTypeSelector(props: IContentTypeSelectorProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedContentType, setSelectedContentType] =
    useState<IContentTypeData>();

  useEffect(() => {
    selectedContentType && props.contentTypesSelected(selectedContentType);
  }, [selectedContentType]);

  useEffect(() => {
    if (props.selectedContentType && selectedContentType === undefined) {
      const filterSelectedContentType = contentTypes.filter(
        (ct) =>
          props.selectedContentType?.toLowerCase() === ct.title.toLowerCase()
      );
      setSelectedContentType(filterSelectedContentType[0]);
    }
  }, [props.selectedContentType]);

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["bottom"]}
        align="start"
        padding={10}
        onClickOutside={() => {
          setIsPopoverOpen(false);
        }}
        content={
          <ContentTypeSelectorPopup
            data={contentTypes}
            closePopup={() => setIsPopoverOpen(false)}
            selectedContentType={selectedContentType || undefined}
            selectedContentTypeChanged={(e) => {
              setSelectedContentType(e);
              if (props.closeOnSelect) {
                setIsPopoverOpen(false);
              }
            }}
          />
        }
      >
        <div
          className={`bg-white border rounded-[7px] px-2 py-2.5 justify-start items-center gap-[5px] flex flex-row ${props.className}`}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <button onClick={() => {}}>
            <div className="flex flex-row gap-[5px]">
              <SvgChannel
                width={20}
                height={20}
                fill={selectedContentType !== undefined ? "#121315" : "#999999"}
              />
              <text className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
                {t("type")}
              </text>
              {selectedContentType !== undefined && (
                <div className="w-[20px] h-[20px] rounded-full bg-red inline-flex justify-center items-center text-H7">
                  {1}
                </div>
              )}
            </div>
          </button>
          {/* <div className="flex flex-row gap-[7px] h-[30px] justify-start items-center ">

                        {(selectedContentTypes !== undefined && selectedContentTypes.length > 0) &&
                            selectedContentTypes.map((contentType) => {
                                return <Tag key={`content_type_${contentType.title}`} tagText={contentType.title} onClose={() => {
                                    const reducedContentTypes = selectedContentTypes.filter(c => c.id !== contentType.id)
                                    setSelectedContentTypes([...reducedContentTypes])
                                    props.contentTypesSelected([...reducedContentTypes])
                                }} />
                            })
                        }
                    </div> */}
        </div>
      </Popover>
    </>
  );
}

export default ContentTypeSelector;
