import * as React from "react";
import type { SVGProps } from "react";
const SvgDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={49}
    viewBox="0 0 49 49"
    {...props}
  >
    <path
      d="M20.5 38.5996V34.5996H8.5C7.4 34.5996 6.45833 34.2079 5.675 33.4246C4.89167 32.6413 4.5 31.6996 4.5 30.5996V10.5996C4.5 9.49961 4.89167 8.55794 5.675 7.77461C6.45833 6.99128 7.4 6.59961 8.5 6.59961H40.5C41.6 6.59961 42.5417 6.99128 43.325 7.77461C44.1083 8.55794 44.5 9.49961 44.5 10.5996V30.5996C44.5 31.6996 44.1083 32.6413 43.325 33.4246C42.5417 34.2079 41.6 34.5996 40.5 34.5996H28.5V38.5996H30.5C31.0667 38.5996 31.5417 38.7913 31.925 39.1746C32.3083 39.5579 32.5 40.0329 32.5 40.5996C32.5 41.1663 32.3083 41.6413 31.925 42.0246C31.5417 42.4079 31.0667 42.5996 30.5 42.5996H18.5C17.9333 42.5996 17.4583 42.4079 17.075 42.0246C16.6917 41.6413 16.5 41.1663 16.5 40.5996C16.5 40.0329 16.6917 39.5579 17.075 39.1746C17.4583 38.7913 17.9333 38.5996 18.5 38.5996H20.5ZM8.5 30.5996H40.5V10.5996H8.5V30.5996Z"
      fill="#121315"
    />
  </svg>
);
export default SvgDesktop;
