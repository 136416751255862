import moment from "moment";
import CreatePresetContentCard from "./CreatePresetContentCard";
import {
  LoopsResponse,
  useGenerateContentFromRevisionMutation,
  useLazyLoopsQuery,
} from "../../services/ContentApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InspirationResponse,
  useLazyGetImageInspirationQuery,
} from "../../services/InspirationApi";
import InspirationsForQuickstartGrid from "./InspirationsForQuickstartGrid";
import { Mediums } from "../../constants/Mediums";
import { ContentType } from "../../constants/ContentType";
import { setNewContent } from "../../store/contentSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../helpers/hooks";
import { ContentWizardView } from "../../models/ContentWizardView";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard, toggleLoading } from "../../store/appSlice";
import { ContentSize, SizeConstants } from "../../constants/ContentSize";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import { Carousel } from "antd";
import { useWindowSize } from "@uidotdev/usehooks";
import PreviousDesignsGridCard from "./PreviousDesignsGridCards";

function ContentVideo() {
  const navigate = useNavigate();
  const [inspirations, setInspirations] = useState<InspirationResponse[]>([]);
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);
  const [imageInspirationPage, setImageInspirationPage] = useState<number>(1);
  const [items, setItems] = useState<LoopsResponse[]>([]);
  const size = useWindowSize();

  const [
    getContentList,
    {
      isLoading: contentListLoading,
      isFetching: contentsFetching,
      data: contentList,
    },
  ] = useLazyLoopsQuery();

  const [
    generateContentFromRevision,
    {
      isLoading: generatingContentFromRevision,
      isSuccess: successGeneratingContentFromRevision,
    },
  ] = useGenerateContentFromRevisionMutation();

  const [
    getInspirations,
    {
      isLoading: inspirationsLoading,
      isFetching: inspirationsFetching,
      error: errorInspirationsLoading,
      data: inspirationsList,
    },
  ] = useLazyGetImageInspirationQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoading(generatingContentFromRevision));
  }, [generatingContentFromRevision]);

  useEffect(() => {
    if (inspirationsList) {
      if (inspirationsList && inspirationsList.length > 0) {
        setInspirations(inspirationsList);
      }
      if (
        inspirationsList.length === 0 ||
        inspirationsList.length < PAGE_SIZE
      ) {
        setImageInspirationPage(0);
      }
    }
  }, [inspirationsList]);

  useEffect(() => {
    getInspirations({
      page: 0,
      size: PAGE_SIZE,
    });

    getContentList({
      page: 0,
      size: PAGE_SIZE,
      platform: "",
      brandId: "",
    });
  }, []);

  useEffect(() => {
    if (contentList && contentList.length > 0) {
      setItems(contentList);
    }
  }, [contentList]);

  const isMobile = () => {
    return size.width && size.width <= 500;
  };

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching || inspirationsLoading));
  }, [inspirationsLoading, inspirationsFetching]);

  const PAGE_SIZE = 10;

  return (
    <div className="flex-col w-full h-full mt-2">
      <div className="flex-col w-[1050px]">
        <Carousel
          slidesToShow={isMobile() ? 1 : 6}
          nextArrow={<CustomRightArrow />}
          prevArrow={<CustomLeftArrow />}
          dots={false}
        >
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  sliderCount: 1,
                  contentType: ContentType.video,
                  video_type: "Video",
                  video_length: 5,
                  video_resolution: "1280x720 (720p HD)",
                  wizardView: ContentWizardView.videoGeneration,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Video" && k.contentType === ContentType.video
                  )?.options?.at(0),
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Video" && k.contentType === ContentType.video
                  ),
                  isCreative: true,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Video"
            sampleImagePath="content-video-video.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  sliderCount: 1,
                  contentType: ContentType.video,
                  wizardView: ContentWizardView.videoGeneration,
                  video_type: "Gif",
                  video_length: 5,
                  video_resolution: "1280x720 (720p HD)",
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Video" && k.contentType === ContentType.video
                  )?.options?.at(1),
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Video" && k.contentType === ContentType.video
                  ),
                  isCreative: true,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Gif"
            sampleImagePath="content-video-gif.png"
          />

          {/*This div is a placeholder for other  CreatePresetContentCard to align*/}
          <div
            className={`flex ${"w-[172px] h-[200px]"} flex-col items-center justify-center`}
          ></div>
        </Carousel>
      </div>

      {items?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              My Recent Designs
            </span>
          </div>
          <div className="flex-col w-[1050px]">
            <Carousel
              draggable={false}
              slidesToShow={isMobile() ? 1 : 6}
              nextArrow={<CustomRightArrow />}
              prevArrow={<CustomLeftArrow />}
              dots={false}
              arrows={isMobile() ? true : false}
            >
              {items.map((k, index) => (
                <PreviousDesignsGridCard
                  key={index}
                  loop={k}
                  updateLoops={() => {}}
                  onSelect={(contentId) => {
                    navigate(`/app/loopdetails/` + contentId);
                  }}
                />
              ))}
            </Carousel>
          </div>
        </>
      )}

      {inspirations?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              Inspirations For Quick Start
            </span>
            <hr className="bg-border mt-1" />
          </div>
          <InspirationsForQuickstartGrid
            onClick={(revisionId) => {
              generateContentFromRevision({ revisionId: revisionId })
                .unwrap()
                .then((k) => {
                  const contentId = k.content_id ?? "";
                  navigate(`/app/loopdetails/` + contentId);
                });
            }}
            onRefresh={() => {
              console.log(`fetching ${imageInspirationPage}`);
              getInspirations({
                page: imageInspirationPage,
                size: PAGE_SIZE,
              });
              setImageInspirationPage(imageInspirationPage + 1);
            }}
            inspirations={inspirations}
          />
        </>
      )}
    </div>
  );
}

export default ContentVideo;
