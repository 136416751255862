import { FunctionComponent } from "react";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { VideoResolution } from "../../../assets/IconComponents";
import { setNewContent } from "../../../store/contentSlice";
import { INewContent } from "../../../models/NewContent";
interface VideoResolutionSelectorProps {
  title?: string;
  className?: string;
  isVertical?: boolean;
  briefMode?: boolean;
  briefContent?: INewContent;
  videoResolutionSelectionUpdated?: (value: string) => void;
}

const VideoResolutionSelector: FunctionComponent<
  VideoResolutionSelectorProps
> = (props: VideoResolutionSelectorProps) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);

  return (
    <div
      className={`self-stretch justify-start items-start gap-3 flex flex-row ${props.className}`}
    >
      <div
        className={`w-[240px] h-7 justify-start items-center gap-2.5 flex ${
          !props.briefMode ? `shrink-0` : ``
        }`}
      >
        <VideoResolution fill={"#FF5555"} />
        <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
          {props.title ?? "Video Resolution"}
        </div>
      </div>

      <div
        className={`flex flex-row ${
          props.isVertical ? "w-full" : "w-2/3"
        } justify-end`}
      >
        <Select
          defaultValue={`${
            props.briefContent
              ? props.briefContent.video_resolution
              : "1280x720 (720p HD)"
          }`}
          style={{ width: `${!props.briefMode ? "260px" : "140px"}` }}
          size="large"
          options={[
            // {
            //   value: "320x240 (240p)",
            //   label: "320x240 (240p)",
            // },
            // {
            //   value: "640x480 (480p)",
            //   label: "640x480 (480p)",
            // },
            {
              value: "854x480",
              label: "854x480",
            },
            // {
            //   value: "960x720",
            //   label: "960x720",
            // },
            {
              value: "1280x720 (720p HD)",
              label: "1280x720 (720p HD)",
            },
            // {
            //   value: "1440x1080",
            //   label: "1440x1080",
            // },
            // {
            //   value: "1920x1080 (1080p Full HD)",
            //   label: "1920x1080 (1080p Full HD)",
            // },
            // {
            //   value: "2560x1440 (1440p 2K WQHD)",
            //   label: "2560x1440 (1440p 2K WQHD)",
            // },
            // {
            //   value: "3840x2160 (2160p 4K UHD)",
            //   label: "3840x2160 (2160p 4K UHD)",
            // },
          ]}
          onChange={(e) => {
            if (!props.briefContent) {
              dispatch(
                setNewContent({
                  ...newContent,
                  video_resolution: e as string,
                } as INewContent)
              );
            } else {
              props.videoResolutionSelectionUpdated?.(e as string);
            }
          }}
        />
      </div>
    </div>
  );
};

export default VideoResolutionSelector;
