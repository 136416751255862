const SvgVideoGif = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill={props.fill}
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M13.2083 10.3542C13.3333 10.2708 13.3958 10.1528 13.3958 10C13.3958 9.84722 13.3333 9.72917 13.2083 9.64583L8.5625 6.66667C8.42361 6.56944 8.28125 6.5625 8.13542 6.64583C7.98958 6.72917 7.91667 6.85417 7.91667 7.02083V12.9792C7.91667 13.1458 7.98958 13.2708 8.13542 13.3542C8.28125 13.4375 8.42361 13.4306 8.5625 13.3333L13.2083 10.3542ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333Z" />
  </svg>
);
export default SvgVideoGif;
