import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FunctionComponent } from "react";
import { FileProps } from "../../../services/BrandApi";
import { setNewContent } from "../../../store/contentSlice";
import VideoSliderCounter from "./VideoSliderCounter";
import ReferenceImageSelector from "./ReferenceImageSelector";
import VideoLengthSelector from "./VideoLengthSelector";
import VideoResolutionSelector from "./VideoResolutionSelector";
import VideoPrompt from "./VideoPrompt";
import VideoContentTypeSelector from "./VideoContentTypeSelector";

interface VideoGenerationContentProps {
  logoSelectionList: FileProps[];
  productSelectionList: FileProps[];
  isInSide?: boolean;
  setIsLogoSelectionOpen?: (status: boolean) => void;
  numberOfImagesChanged?: (num: number) => void;
  bgRemoved?: (string) => void;
}

const VideoGenerationContent: FunctionComponent<VideoGenerationContentProps> = (
  props: VideoGenerationContentProps
) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);

  return (
    <>
      <div
        className={`w-full ${
          props.isInSide
            ? "min-h-[calc(70vh-135px)] max-h-[calc(85vh-80px)] overflow-scroll mb-[80px]"
            : "h-[calc(100vh-135px)]"
        } flex-col p-[20px] gap-[12px] inline-flex border-b-1 overflow-scroll scrollbar-hide`}
      >
        <ReferenceImageSelector
          productSelectionList={props.productSelectionList}
          bgRemoved={(str) => {
            if (props.bgRemoved) {
              props.bgRemoved(str);
            }
          }}
        />

        <div className="w-full  p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <VideoPrompt hideTitle={props.isInSide} />
        </div>
        <div className="w-full  p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <VideoContentTypeSelector />
        </div>
        <div className="w-full  p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <VideoLengthSelector />
        </div>
        <div className="w-full  p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <VideoResolutionSelector />
        </div>
        <div className="w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <VideoSliderCounter
            minCount={1}
            maxCount={2}
            numberOfImagesChanged={(num) => {
              if (props.numberOfImagesChanged) {
                props.numberOfImagesChanged(num);
              }
              dispatch(
                setNewContent({
                  ...newContent,
                  sliderCount: num,
                })
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default VideoGenerationContent;
