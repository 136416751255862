import { useDispatch } from "react-redux";
import { Button } from "antd";

import { Desktop, LogoDark } from "../../assets/IconComponents";

const RedirectToDesktop = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="flex flex-col h-screen items-center justify-center bg-lightBg">
        <div className="flex flex-col w-full items-center max-lg:pt-6 lg:justify-center p-5">
          <LogoDark
            width={200}
            height={32}
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 150 75"
            className="mb-[40px] cursor-pointer"
            onClick={() => {
              window.location.replace("https://www.evercopy.ai/");
            }}
          />
          <div
            className={`w-full sm:w-2/3 md:w-2/3 lg:w-2/5 rounded-xl bg-white p-5 gap-5 flex items-center justify-center flex-col border`}
          >
            <div className="flex flex-col pt-8 pr-4 pl-4 pb-8 gap-6 items-center justify-center">
              <Desktop />
              <div className="flex flex-col w-full gap-1 items-center justify-center">
                <span
                  className="text-black"
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                  }}
                >
                  Continue on Desktop
                </span>
                <span
                  className="text-black"
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    textAlign: "center",
                  }}
                >
                  Our platform is optimized for desktop to give you the best
                  experience. Please continue your sign-up on a desktop.
                </span>
              </div>
              <Button
                size="large"
                onClick={() => {
                  navigator.clipboard.writeText(
                    process.env.REACT_APP_URL ?? ""
                  );
                }}
              >
                Copy Link
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedirectToDesktop;
