import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    videoPrompt: build.mutation<VideoPromptApiResponse, VideoPromptApiArg>({
      query: (queryArg) => ({
        url: `/video/videoprompt`,
        method: "POST",
        body: queryArg.videoPromptRequest,
      }),
    }),
    saveVideoContent: build.mutation<
      SaveVideoContentApiResponse,
      SaveVideoContentApiArg
    >({
      query: (queryArg) => ({
        url: `/video/save`,
        method: "POST",
        body: queryArg.createVideoRequest,
      }),
    }),
    createVideo: build.mutation<CreateVideoApiResponse, CreateVideoApiArg>({
      query: (queryArg) => ({
        url: `/video/create`,
        method: "POST",
        body: queryArg.createVideoRequest,
      }),
    }),
    applyRevisionUpdate: build.mutation<
      ApplyRevisionUpdateApiResponse,
      ApplyRevisionUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/video/apply`,
        method: "POST",
        body: queryArg.videoRevisionRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type VideoPromptApiResponse = /** status 200 OK */ VideoPromptResponse;
export type VideoPromptApiArg = {
  videoPromptRequest: VideoPromptRequest;
};
export type SaveVideoContentApiResponse = /** status 200 OK */ Content;
export type SaveVideoContentApiArg = {
  createVideoRequest: CreateVideoRequest;
};
export type CreateVideoApiResponse = /** status 200 OK */ VideoResponse;
export type CreateVideoApiArg = {
  createVideoRequest: CreateVideoRequest;
};
export type ApplyRevisionUpdateApiResponse = /** status 200 OK */ Revision[];
export type ApplyRevisionUpdateApiArg = {
  videoRevisionRequest: VideoRevisionRequest;
};
export type VideoPromptResponse = {
  prompt?: string;
};
export type VideoPromptRequest = {
  referred_image_id?: string;
  referred_image?: string;
};
export type Id = {
  content_id?: string;
  revision_id?: string;
};
export type Content = {
  id?: Id;
  name?: string;
  type?: "image" | "text" | "video";
  platform?: string;
  purpose?: string;
  filters?: string;
  revisionGroupCount?: number;
  creative?: string;
  activityData?: string;
  photoshootContent?: string;
  videoContent?: string;
  cta?: boolean;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  words_limit?: number;
  sentences_limit?: number;
  image_height?: number;
  image_width?: number;
  brand_id?: string;
  logo_file_id?: string;
  product_file_id?: string;
  is_favorite?: boolean;
  read_only?: boolean;
};
export type CreateVideoRequest = {
  referred_image_id?: string;
  brand_id?: string;
  referred_image?: string;
  prompt_text?: string;
  video_type?: string;
  video_length?: number;
  video_resolution?: string;
  count: number;
};
export type Revision = {
  id?: Id;
  type?: string;
  fileId?: string;
  aiImage?: string;
  headerText?: string;
  footerText?: string;
  templateID?: string;
  colorSet?: string;
  fontSet?: string;
  generatedFrom?: string;
  original?: boolean;
  photoshootRevision?: string;
  fontSetIndex?: {
    [key: string]: number;
  };
  colorSetIndex?: {
    [key: string]: number;
  };
  ctatext?: string;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  revision_parent_id?: string;
  revision_group?: number;
  generated_content?: string;
  is_favorite?: boolean;
  ai_model?: string;
};
export type VideoResponse = {
  content_id?: string;
  revisions?: Revision[];
};
export type VideoRevisionRequest = {
  content_id: string;
  referred_image_id?: string;
  referred_image?: string;
  prompt_text?: string;
  video_type?: string;
  video_length?: number;
  video_resolution?: string;
  update_brief: boolean;
  refresh: boolean;
  revision_parent_id?: string;
  revision_group?: number;
  count?: number;
};
export const {
  useVideoPromptMutation,
  useSaveVideoContentMutation,
  useCreateVideoMutation,
  useApplyRevisionUpdateMutation,
} = injectedRtkApi;
