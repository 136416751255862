import { useState } from "react";

interface ICreatePresetContentCardProps {
  title: string;
  sampleImagePath: string;
  onClick: () => void;
  isInSide?: boolean;
}
function CreatePresetContentCard(props: ICreatePresetContentCardProps) {
  const [additionalClassAttr, setAdditionalClassAttr] = useState("");
  return (
    <div
      onMouseOver={() => setAdditionalClassAttr("border-1 border-red")}
      onMouseLeave={() =>
        setAdditionalClassAttr(
          `${props.isInSide ? "border-1 border-border" : ""}`
        )
      }
      className={`flex ${
        props.isInSide
          ? "w-[31%] h-auto p-1  m-1 border-1 border-border"
          : "w-[172px] h-[200px]"
      } flex-col items-center justify-center cursor-pointer   `}
      onClick={() => props.onClick()}
    >
      <div
        className={`flex-col ${
          props.isInSide ? "w-[calc(100%-20px)] h-auto" : "w-[165px] h-[172px]"
        }  bg-lightBg items-center justify-center mx-auto my-auto rounded-[8px] ${additionalClassAttr}`}
      >
        <img
          className="p-0.5"
          src={require("../../assets/" +
            (props.sampleImagePath ?? "content-twitter-sample.png"))}
          alt="asics" // Set the width and height of the image
        />
      </div>
      <span
        className={`${
          props.isInSide ? "text-H7" : "text-BodySmall"
        } mt-2 mx-2 text-black w-full overflow-hidden text-center font-medium text-H7`}
      >
        {props.title ?? ""}
      </span>
    </div>
  );
}

export default CreatePresetContentCard;
