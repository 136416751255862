export enum ContentWizardView {
  contentDetails,
  blogDetails,
  audience,
  productLibrary,
  logoLibrary,
  inspoLibrary,
  creativeReferredImageLibrary,
  aiProductPhotoshootImageLibrary,
  technicalDocumentsLibrary,
  aiProductPhotoshoot,
  videoGeneration,
  videoReferenceImageLibrary
}