import ContentContainer from "../../components/ContentContainer";
import Icon from "../../components/Icon";
import { Mediums } from "../../constants/Mediums";

import { useEffect, useState } from "react";
import { t } from "i18next";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import { useAppDispatch } from "../../helpers/hooks";
import {
  useDeleteContentMutation,
  useEditNameMutation,
} from "../../services/ContentApi.old/ContentApi";
import {
  useEditTextMutation,
  useFavoriteMutation,
} from "../../services/TextApi.old/TextApi";
import { setDialog, toggleDialog } from "../../store/appSlice";
import ContentImage from "../../components/ContentImage";
import { useFavoriteImageMutation } from "../../services/ImageApi.old/ImageApi";
import CopyLoop from "../../components/CopyLoop";
import { Liked, Like, Copy, Delete } from "../../assets/IconComponents";
import {
  Content,
  LoopsResponse,
  Revision,
  useFavorite1Mutation,
} from "../../services/ContentApi";
import SvgEyeIcon from "../../assets/IconComponents/EyeIcon";
import { useWindowSize } from "@uidotdev/usehooks";
import { SelectedLoopsPageListType } from "../Loops/LoopsPageListTypeSelector";
import { Utils } from "../../helpers/Utils";
import ReactPlayer from "react-player";

export interface IPreviousDesignsGridCardProps {
  loop: LoopsResponse;
  updateLoops: () => void;
  onSelect: (contentId: string) => void;
}

function PreviousDesignsGridCard(props: IPreviousDesignsGridCardProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [updateText, { isSuccess: textUpdated }] = useEditTextMutation();
  const [editName, { isSuccess: nameUpdated }] = useEditNameMutation();

  const [deleteContent, { isSuccess: contentDeleted }] =
    useDeleteContentMutation();
  const [setTextFavorite, { isSuccess: textFavoriteUpdated }] =
    useFavoriteMutation();
  const [setImageFavorite, { isSuccess: imageFavoriteUpdated }] =
    useFavoriteImageMutation();
  const [updateFav, { isSuccess: favUpdated }] = useFavorite1Mutation();

  const [isFavori, setFavori] = useState(false);
  const [cardContent, setCardContent] = useState<Revision>();
  const size = useWindowSize();
  const [videoType, setVideoType] = useState<"Gif" | "Video">("Video");

  useEffect(() => {
    setCardContent({
      revision_id:
        props.loop.content?.type == "text"
          ? props.loop.revision?.id?.revision_id ?? ""
          : props.loop.revision?.id?.revision_id ?? "",
      revision_parent_id:
        props.loop.content?.type == "text"
          ? props.loop.revision?.revision_parent_id ?? ""
          : props.loop.revision?.revision_parent_id ?? "",
      generated_content:
        props.loop.content?.type == "text"
          ? props.loop.revision?.generated_content ?? ""
          : "",
      is_favorite: props.loop.content?.is_favorite ?? false,
      fileId:
        props.loop.content?.type == "image"
          ? props.loop.revision?.generated_content ?? ""
          : "",
      contentId: props.loop.revision?.id?.content_id ?? "",
    } as Revision);
  }, [props.loop]);

  useEffect(() => {
    if (props.loop.content) {
      getVideoTypeFromContent(props.loop.content);
    }
  }, [props.loop.content]);

  const getTruncatedText = (text: string) => {
    let truncationLimit = 40;

    if (size.width && size.width > 1350 && size.width < 1600)
      truncationLimit = 30;
    else if (size.width && size.width > 580 && size.width <= 1350)
      truncationLimit = 20;
    return `${
      (text.length ?? 0) > truncationLimit
        ? text.slice(0, truncationLimit) + "..."
        : text
    }`;
  };

  const getVideoTypeFromContent = (content: Content) => {
    const videoContent = JSON.parse(content?.videoContent ?? "{}");
    console.log("videoContent", videoContent);
    setVideoType(videoContent.video_type);
  };

  return (
    <div className="flex flex-col gap-[5px]">
      <ContentContainer
        className={`  h-[215px] cursor-pointer mx-1`}
        onClick={() => {
          props.onSelect(props.loop.content?.id?.content_id ?? "");
        }}
        hoverable
      >
        <div className={`flex w-full flex-col items-start gap-[10px] p-0.5`}>
          {props.loop.content?.type === "text" && (
            <div className={`w-full relative h-[210px] rounded-[8px]`}>
              <p
                style={{
                  whiteSpace: "pre-wrap",
                  backgroundColor: "rgba(246, 246, 246)",
                }}
                className={`w-full h-[210px] p-3 overflow-scroll scrollbar-hide text-black text-BodySmall text-[13px] whitespace-pre-wrap pb-2 rounded-[8px]`}
              >
                {props?.loop?.revision?.generated_content ?? ""}
              </p>
              {/*
              <div
                className={`absolute h-[210px] top-0 left-0 right-0 bottom-0`}
                style={{
                  borderRadius: 12,
                  background:
                    "linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.00) 30%)",
                }}
              ></div>
              */}
            </div>
          )}
          {props.loop.content?.type === "image" && (
            <ContentImage
              id={cardContent?.fileId ?? ""}
              className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain"
            />
          )}
          {props.loop.content?.type === "video" && videoType === "Video" && (
            <div className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain">
              <ReactPlayer
                url={props.loop?.revision?.generated_content}
                width="100%"
                height="100%"
                controls={true}
                pip={true}
                // config={
                //   {
                //     playerVars: {
                //       autoplay: 0,
                //       loop: 1,
                //       controls: 1,
                //       color: "white",
                //       modestbranding: 0,
                //       rel: 0,
                //     },
                //   } as Config
                // }
              />
            </div>
          )}
          {props.loop.content?.type === "video" && videoType === "Gif" && (
            <div className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain">
              <img
                src={props.loop.revision?.generated_content}
                alt="Scaled Image"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                }}
              />
            </div>
          )}
        </div>
      </ContentContainer>
      <div className={`flex flex-col mt-1 ml-1 justify-start w-full`}>
        <span className="text-BodyMedium font-inter-tight font-medium text-black">
          {getTruncatedText(Utils.formatPlatformName(videoType ?? ""))}
        </span>
      </div>
    </div>
  );
}

export default PreviousDesignsGridCard;
