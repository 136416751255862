import { FunctionComponent, useEffect, useState } from "react";
import ReferenceFileSelectorIcon from "../../../assets/IconComponents/ReferenceFileSelectorIcon";
import ToggleButton from "../../../components/ToggleButton";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { INewContent } from "../../../models/NewContent";
import { FileProps } from "../../../services/BrandApi";
import { setNewContent } from "../../../store/contentSlice";

interface ReferenceImageSelectorProps {
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  productSelectionList: FileProps[];
  briefContent?: INewContent;
  onSelectionChanged?: (data: FileProps[]) => void;
  onListingChanged?: (data: FileProps[]) => void;
  bgRemoved?: (string) => void;
}

const ReferenceImageSelector: FunctionComponent<ReferenceImageSelectorProps> = (
  props: ReferenceImageSelectorProps
) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);
  const [selectionList, setSelectionList] = useState<FileProps[]>([]);

  useEffect(() => {
    if (props.productSelectionList.length !== 0) {
      setSelectionList(props.productSelectionList);
      selectImage();
    }
  }, [props.productSelectionList]);

  useEffect(() => {
    if (
      selectionList.length > 0 &&
      newContent?.productFileId &&
      selectionList.filter((k) => k.id === newContent.referenceFileId)
        .length === 0
    ) {
      dispatch(
        setNewContent({
          ...newContent,
          referenceFileId: selectionList[0].id,
        })
      );
    }
  }, [selectionList]);

  function selectImage() {
    if (!newContent?.referenceFileId) {
      if (props.productSelectionList.length > 0) {
        dispatch(
          setNewContent({
            ...newContent,
            referenceFileId: props.productSelectionList[0].id,
          })
        );
      }
    } else {
      if (props.productSelectionList.length > 0) {
        if (
          props.productSelectionList.filter(
            (k) => k.id === newContent.referenceFileId
          ).length === 0
        ) {
          dispatch(
            setNewContent({
              ...newContent,
              referenceFileId: props.productSelectionList[0].id,
            })
          );
        }
      }
    }
  }

  function getSelectedFileIds() {
    if (props.briefContent) {
      return props.briefContent?.referenceFileId !== undefined
        ? [props.briefContent.referenceFileId]
        : [];
    } else {
      return newContent?.referenceFileId ? [newContent.referenceFileId] : [];
    }
  }

  return (
    <>
      <div
        className={`w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-4 inline-flex ${props.className}`}
      >
        <div
          className={`w-full justify-between items-center inline-flex ${props.headerClassName}`}
        >
          <div className="justify-start items-center gap-2.5 flex">
            <ReferenceFileSelectorIcon
              fill={!newContent?.referenceFileId ? "#8E8E8E" : "#FF5555"}
              className="flex shrink-0"
            />
            <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              Add Reference Image
            </text>
          </div>

          {props.briefContent && (
            <div
              onClick={() => {
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.logoLibrary,
                  })
                );
              }}
              className="flex items-center self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
            ></div>
          )}
        </div>

        {
          <div
            className={`self-stretch h-[150px] flex-col justify-start items-start gap-2.5 flex ${props.contentClassName}`}
          >
            {((props.briefContent &&
              props.briefContent.referenceFileId &&
              selectionList.length > 0) ||
              (props.briefContent && !props.briefContent.referenceFileId) ||
              !props.briefContent) && (
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <UploadFileInfinite
                  size={props.briefContent ? "brief" : "video_reference"}
                  maxFileCount={10}
                  automaticallySelectUploadedFile={true}
                  uploadedFiles={selectionList.slice(0, 5) ?? []}
                  title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                  description="Upload .jpg or .png files. Max 25mb."
                  filesChanged={(f: any[]) => {
                    if (f && f.length > 0) {
                      const updatedFileSet = [
                        ...f.slice(0, 5).map((k) => k.uploadedId),
                      ];
                      const previousFileSet = [
                        ...selectionList.map((m) => m.id),
                      ];
                      const difference = updatedFileSet.filter(
                        (k) => !previousFileSet.includes(k)
                      );

                      setSelectionList([
                        ...f.slice(0, 5).map((k) => {
                          return { id: k.uploadedId };
                        }),
                      ]);
                      if (props.onListingChanged) {
                        props.onListingChanged([
                          ...f.slice(0, 5).map((k) => {
                            return { id: k.uploadedId };
                          }),
                        ]);
                      }

                      if (difference.length > 0) {
                        dispatch(
                          setNewContent({
                            ...newContent,
                            referenceFileId: difference.at(0),
                          })
                        );
                      }
                    }
                  }}
                  selectionChanged={(pickedFiles) => {
                    if (props.briefContent === undefined) {
                      if (
                        newContent?.referenceFileId === undefined &&
                        pickedFiles.length === 0
                      ) {
                        return;
                      }

                      const newProductFileId =
                        pickedFiles.length > 0
                          ? pickedFiles.at(0)?.id
                          : undefined;

                      if (newProductFileId != newContent?.referenceFileId) {
                        dispatch(
                          setNewContent({
                            ...newContent,
                            referenceFileId: newProductFileId,
                          })
                        );
                      }
                    } else {
                      if (props.onSelectionChanged) {
                        props.onSelectionChanged(pickedFiles);
                      }
                    }
                  }}
                  selectionEnabled={true}
                  maxSelectionSize={1}
                  selectedFileIds={getSelectedFileIds()}
                  maxThumbnailCount={5}
                  addNewFileToFront={true}
                  // backgroundRemoved={
                  //   // (props.callingParent ?? "") === "productphotoshoot"
                  //   //   ? (str) => {
                  //   //     if (props.bgRemoved) {
                  //   //       props.bgRemoved(str);
                  //   //     }
                  //   //   }
                  //   //   : undefined
                  // }
                  showDeleteButton={false}
                  showRefreshButton={false}
                  showRemoveBgButton={true}
                />
              </div>
            )}
            {props.briefContent === undefined && (
              <div
                onClick={() => {
                  {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        wizardView:
                          ContentWizardView.videoReferenceImageLibrary,
                      })
                    );
                  }
                }}
                className="self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
              >
                <a>View Library</a>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default ReferenceImageSelector;
