import { FunctionComponent, useEffect, useState } from "react";
import { Radio } from "antd";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { VideoContentType } from "../../../assets/IconComponents";
import { setNewContent } from "../../../store/contentSlice";
import { INewContent } from "../../../models/NewContent";

interface VideoContentTypeSelectorProps {
  title?: string;
  className?: string;
  isVertical?: boolean;
  briefMode?: boolean;
  briefContent?: INewContent;
  contentTypeUpdated?: (value: "Video" | "Gif") => void;
}

const VideoContentTypeSelector: FunctionComponent<
  VideoContentTypeSelectorProps
> = (props: VideoContentTypeSelectorProps) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);

  const radioStyle = {
    width: `${!props.briefMode ? "130px" : "70px"}`,
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
  };

  return (
    <div
      className={`self-stretch justify-start items-start gap-3 flex flex-row ${props.className}`}
    >
      <div
        className={`w-[240px] h-7 justify-start items-center gap-2.5 flex ${
          !props.briefMode ? `shrink-0` : ``
        }`}
      >
        <VideoContentType fill={"#FF5555"} />
        <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
          {props.title ?? "Content Type"}
        </div>
      </div>

      <div
        className={`flex flex-row ${
          props.isVertical ? "w-full" : "w-2/3"
        } justify-end`}
      >
        <Radio.Group
          size={"large"}
          defaultValue={`${
            props.briefContent
              ? props.briefContent.video_type
              : newContent?.video_type
              ? newContent?.video_type
              : "Video"
          }`}
          optionType="button"
          onChange={(e) => {
            if (props.briefContent === undefined) {
              dispatch(
                setNewContent({
                  ...newContent,
                  video_type: e.target.value as "Video" | "Gif",
                } as INewContent)
              );
            } else {
              props.contentTypeUpdated?.(e.target.value as "Video" | "Gif");
            }
          }}
        >
          <Radio.Button value="Video" style={radioStyle}>
            Video
          </Radio.Button>
          <Radio.Button value="Gif" style={radioStyle}>
            Gif
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default VideoContentTypeSelector;
