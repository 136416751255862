import ContentContainer from "../ContentContainer";
import { useEffect, useState } from "react";
import { IContentTypeData } from "./ContentTypeSelector";
import ContentTypeSelectorItem from "./ContentTypeSelectorItem";
import { ContentType } from "../../constants/ContentType";

export interface IContentTypeSelectorPopupProps {
  data: IContentTypeData[];
  closePopup: () => void;
  selectedContentType: IContentTypeData | undefined;
  selectedContentTypeChanged: (data: IContentTypeData | undefined) => void;
}

function ContentTypeSelectorPopup(props: IContentTypeSelectorPopupProps) {
  const [selectedItem, setSelectedItem] = useState<IContentTypeData>();

  useEffect(() => {
    setSelectedItem(props.selectedContentType);
  }, [props.selectedContentType]);

  return (
    <ContentContainer
      hoverable={false}
      className="w-[250px] h-[210px]  overflow-auto"
      children={
        <>
          {/*Body*/}
          <div className="p-[12px]">
            {props.data.map((k) => (
              <ContentTypeSelectorItem
                data={k}
                selected={selectedItem?.id === k.id}
                itemSelected={(e, isSelected) => {
                  var selectedContentType: IContentTypeData | undefined =
                    undefined;

                  if (e.id === selectedItem?.id) {
                    selectedContentType = undefined;
                  } else {
                    selectedContentType = e;
                  }

                  setSelectedItem(selectedContentType);
                  props.selectedContentTypeChanged(selectedContentType);
                }}
              />
            ))}
          </div>
        </>
      }
    />
  );
}

export default ContentTypeSelectorPopup;
