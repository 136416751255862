import { FunctionComponent, useEffect, useState } from "react";
import Icon from "../../../components/Icon";
import SvgGpt35Icon from "../../../assets/IconComponents/Gpt35Icon";
import {
  LoopChannels,
  LoopDetailChannel,
} from "../LoopDetailsHeader/Channel/LoopDetailChannels";
import IconButton from "../../../components/IconButton";
import {
  Copy,
  Delete,
  Edit,
  Like,
  Liked,
} from "../../../assets/IconComponents";
import { t } from "i18next";
import SvgGenerateSimilarIcon from "../../../assets/IconComponents/GenerateSimilarIcon";
import { Revision, useFavoriteMutation } from "../../../services/RevisionApi";
import { useSaveRevisionMutation } from "../../../services/CommunityContentApi";
import { ShimmerText, ShimmerTitle } from "react-shimmer-effects";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import TextContentDetailPopup from "../../../components/TextContentDetailPopup";
import { Content } from "../../../services/ContentApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import PrimaryButton from "../../../components/PrimaryButton";
import { setDialog, toggleDialog } from "../../../store/appSlice";
import { ArrowLeftOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface ContentGroupItemProps {
  content: Content;
  revision: Revision;
  generateSimilar: (revision: Revision) => void;
  deleteItem: () => void;
  updatePage: () => void;
  isInSide?: boolean;
  moveContent?: (param: any) => void;
  loopChannels?: LoopDetailChannel[];
}

const ContentGroupItem: FunctionComponent<ContentGroupItemProps> = (
  props: ContentGroupItemProps
) => {
  const [showContentDetail, setShowContentDetail] = useState(false);
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [isFavori, setFavori] = useState(false);
  const [isCommunityShared, setCommunityShared] = useState(false);
  const [aiModelsLocal, setAiModelsLocal] = useState<LoopDetailChannel[]>([]);
  const [channel, setChannel] = useState<LoopDetailChannel>(LoopChannels[0]);
  const [favRev, { isSuccess: favSuccess }] = useFavoriteMutation();
  const [shareRev, { isSuccess: shareSuccess }] = useSaveRevisionMutation();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [copyButtonText, setCopyButtonText] = useState(t("copy"));
  const isContentLoading = useAppSelector((k) => k.content.isContentLoading);
  const activeAIModels = useAppSelector((k) => k.persisted.activeAIModels);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.revision) {
      setFavori(props.revision.is_favorite ?? false);
      let foundChannel = aiModelsLocal.find(
        (k) => k.name.toLowerCase() === props.revision.ai_model?.toLowerCase()
      )!;
      if (foundChannel) {
        setChannel(foundChannel);
      }
    }
  }, [props.revision, aiModelsLocal]);

  useEffect(() => {
    if (props.loopChannels) {
      setAiModelsLocal(props.loopChannels);
    }
  }, [props.loopChannels]);

  const copyContent = () => {
    if (props.revision.generated_content) {
      setCopyButtonText(t("copied-0"));
      copyToClipboard(props.revision.generated_content);
      setTimeout(() => {
        setCopyButtonText(t("copy"));
      }, 2000);
    }
  };

  useEffect(() => {
    if (favSuccess) props.updatePage();
  }, [favSuccess]);

  const showSelectOneModelDialogue = () => {
    dispatch(
      setDialog({
        actions: (
          <>
            <PrimaryButton
              className="h-12 w-20 ml-2"
              title={t("ok")}
              onClick={() => {
                dispatch(toggleDialog(false));
              }}
            />
          </>
        ),
        title: t("generate-similar"),
        message: t("you-should-at-least-select-one-ai-model"),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );
    dispatch(toggleDialog(true));
  };

  return (
    <>
      <TextContentDetailPopup
        editingEnabled={editingEnabled}
        fullScreen
        closable
        loopChannels={aiModelsLocal}
        show={showContentDetail}
        onClose={() => {
          setShowContentDetail(false);
          setEditingEnabled(false);
        }}
        className="!-mt-20 z-20"
        content={props.content}
        revision={props.revision}
        generateSimilar={() => {
          if (
            activeAIModels.filter((k) => k !== "ERROR").length === 0 &&
            props.isInSide != true
          ) {
            showSelectOneModelDialogue();
            return;
          }
          props.generateSimilar(props.revision);
        }}
        updatePage={props.updatePage}
        deleteRevisionItem={props.deleteItem}
      />
      <div
        className={`h-[280px] p-[18px] border-1 border-border bg-white rounded-[10px] transform-gpu  revision_item_${
          props.revision.id?.revision_id
        }  ${
          props.revision.type !== "loading"
            ? "hover:border-red   cursor-pointer "
            : ""
        } `}
        onClick={() => {
          if (props.revision.type !== "loading" && !isContentLoading)
            if (props.moveContent) {
            } else {
              setShowContentDetail(true);
            }
        }}
      >
        {props.revision.type === "loading" && (
          <div className="py-2">
            <div>
              <span className="text-H6 text-black flex justify-between flex-row">
                <ShimmerTitle line={1} variant="secondary" className="w-36" />
                <ShimmerTitle
                  line={1}
                  variant="secondary"
                  className="w-[30px]"
                />
              </span>
            </div>
            <p className="text-BodySmall text-black mt-3">
              <ShimmerText line={8} gap={10} />
            </p>
            <p className="text-H6 text-black flex justify-between flex-row">
              <ShimmerTitle line={1} variant="secondary" className="w-20" />
              <div className="flex flex-row">
                <ShimmerTitle line={1} variant="secondary" className="w-11" />
                <ShimmerTitle
                  line={1}
                  variant="secondary"
                  className="w-11 ml-1"
                />
                <ShimmerTitle
                  line={1}
                  variant="secondary"
                  className="w-11 ml-1"
                />
              </div>
            </p>
          </div>
        )}
        {props.revision.type !== "loading" && (
          <>
            <div className="flex flex-row w-full items-center justify-between">
              <p
                className="text-H8 flex flex-row items-center px-[6px] py-[4px] rounded-[4px] w-fit"
                style={{
                  background: channel.bgColor,
                  color: channel.textColor,
                }}
              >
                {
                  <channel.icon
                    width={16}
                    height={16}
                    viewBox="0 0 22 22"
                    className="mr-1"
                  />
                }
                {channel.name}
              </p>
              <div className="flex flex-row">
                {props.revision.original && (
                  <p className="text-BodySmall text-gray">Original</p>
                )}
                <IconButton
                  className="hover:w-20 ml-[15px]"
                  title={isFavori ? t("unlike") : t("like")}
                  color={isFavori ? "red" : "gray"}
                  icon={isFavori ? Liked : Like}
                  onClick={(e) => {
                    if (!isContentLoading && !props.content.read_only) {
                      e.stopPropagation();
                      favRev({
                        revisionId: props.revision.id?.revision_id ?? "",
                        isFavorite: !isFavori,
                      });
                      setFavori(!isFavori);
                    }
                  }}
                />
                {/* <IconButton
                  className="hover:w-20 ml-[15px]"
                  title={isCommunityShared ? t("unshare") : t("share")}
                  color={isCommunityShared ? "red" : "gray"}
                  icon={isCommunityShared ? Liked : Like}
                  onClick={(e) => {
                    if (!isContentLoading && !props.content.read_only) {
                      e.stopPropagation();
                      shareRev({
                        saveCommunityContentRequest: {
                          revisionId: props.revision.id?.revision_id ?? "",
                        }
                      });
                      setCommunityShared(!isCommunityShared);
                    }
                  }}
                /> */}
              </div>
            </div>
            <div
              className={`relative ${
                props.content.read_only ? "h-[210px]" : "h-[180px]"
              } mt-2  rounded-[8px]`}
            >
              <p
                style={{
                  whiteSpace: "pre-wrap",
                  backgroundColor: "rgba(246, 246, 246)",
                }}
                className={`w-full ${
                  props.content.read_only ? "h-[210px]" : "h-[170px]"
                } p-3 overflow-scroll scrollbar-hide text-black text-BodyMedium whitespace-pre-wrap pb-2 rounded-[8px]`}
              >
                {props.revision.generated_content ?? ""}
              </p>
              {props.isInSide !== true && (
                <div
                  className={`absolute ${
                    props.content.read_only ? "h-[210px]" : "h-[180px]"
                  } top-0 left-0 right-0 bottom-0`}
                  style={{
                    borderRadius: 12,
                    background:
                      "linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.00) 30%)",
                  }}
                ></div>
              )}
            </div>
            {!props.content.read_only && (
              <div className="w-full flex justify-between mt-2">
                <IconButton
                  className="hover:w-[250px] ml-[10px] !text-red outline-none"
                  title={t("generate-similar")}
                  icon={SvgGenerateSimilarIcon}
                  onClick={(e) => {
                    if (!isContentLoading) {
                      e.stopPropagation();
                      if (
                        activeAIModels.filter((k) => k !== "ERROR").length ===
                          0 &&
                        props.isInSide != true
                      ) {
                        showSelectOneModelDialogue();
                        return;
                      }
                      props.generateSimilar(props.revision);
                    }
                  }}
                />
                <div className="flex flex-row items-center mr-[10px]">
                  <IconButton
                    color="#000000"
                    className="hover:w-20 ml-[10px]"
                    title={copyButtonText ?? ""}
                    icon={Copy}
                    onClick={(e) => {
                      if (!isContentLoading) {
                        e.stopPropagation();
                        copyContent();
                      }
                    }}
                  />
                  {props.isInSide ? (
                    <>
                      <Button
                        type={"primary"}
                        onClick={() => {
                          if (props.moveContent) {
                            props.moveContent(props.revision);
                          }
                        }}
                        className={"ml-2"}
                      >
                        Insert
                      </Button>
                    </>
                  ) : (
                    <>
                      <IconButton
                        color="#000000"
                        className="hover:w-20 ml-[5px]"
                        title={t("edit")}
                        icon={Edit}
                        onClick={(e) => {
                          if (!isContentLoading) {
                            e.stopPropagation();
                            setEditingEnabled(true);
                            setShowContentDetail(true);
                          }
                        }}
                      />
                      <IconButton
                        color="#000000"
                        className="hover:w-20 ml-[5px]"
                        title={t("delete")}
                        icon={Delete}
                        onClick={(e) => {
                          if (!isContentLoading) {
                            e.stopPropagation();
                            props.deleteItem();
                          }
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContentGroupItem;
