import * as React from "react";
const ReferenceFileSelectorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <rect opacity="0.1" width="28" height="28" rx="4" fill={props.fill} />
    <path d="M13.9266 15.0733L12.2733 13.4133C11.9197 13.0778 11.4508 12.8908 10.9633 12.8908C10.4758 12.8908 10.007 13.0778 9.65331 13.4133L7.33331 15.7333V18.6667C7.3349 19.1966 7.54612 19.7044 7.92085 20.0791C8.29558 20.4539 8.80337 20.6651 9.33331 20.6667H17.3333C17.4253 20.668 17.5166 20.6593 17.6066 20.64C18.0835 20.5804 18.5209 20.3451 18.8333 19.98L13.9266 15.0733ZM21.138 8.862L19.138 6.862C19.013 6.73702 18.8434 6.66681 18.6666 6.66681C18.4899 6.66681 18.3203 6.73702 18.1953 6.862L16.1953 8.862C16.0739 8.98774 16.0067 9.15614 16.0082 9.33093C16.0097 9.50573 16.0798 9.67294 16.2034 9.79655C16.327 9.92015 16.4942 9.99026 16.669 9.99178C16.8438 9.9933 17.0122 9.92611 17.138 9.80467L18 8.94267V12.6667C18 12.8435 18.0702 13.013 18.1952 13.1381C18.3203 13.2631 18.4898 13.3333 18.6666 13.3333C18.8435 13.3333 19.013 13.2631 19.1381 13.1381C19.2631 13.013 19.3333 12.8435 19.3333 12.6667V8.94267L20.1953 9.80467C20.321 9.92611 20.4894 9.9933 20.6642 9.99178C20.839 9.99026 21.0063 9.92015 21.1299 9.79655C21.2535 9.67294 21.3236 9.50573 21.3251 9.33093C21.3266 9.15614 21.2594 8.98774 21.138 8.862Z" fill={props.fill} />
    <path opacity="0.1" d="M14.5933 15.74L18.8333 19.98C19.1553 19.6187 19.3333 19.1513 19.3333 18.6667V16.92L17.0133 14.6067C16.6604 14.2696 16.1913 14.0816 15.7033 14.0816C15.2154 14.0816 14.7462 14.2696 14.3933 14.6067L13.928 15.072L14.5933 15.74Z" fill={props.fill} />
    <path opacity="0.23" d="M18.6666 13.3333C18.4898 13.3333 18.3203 13.2631 18.1952 13.1381C18.0702 13.013 18 12.8435 18 12.6667V8.94266L17.138 9.80466C17.0122 9.9261 16.8438 9.99329 16.669 9.99177C16.4942 9.99025 16.327 9.92014 16.2034 9.79654C16.0798 9.67293 16.0097 9.50572 16.0082 9.33092C16.0067 9.15613 16.0739 8.98772 16.1953 8.86199L16.3906 8.66666H9.33331C8.80337 8.66824 8.29558 8.87946 7.92085 9.25419C7.54612 9.62892 7.3349 10.1367 7.33331 10.6667V15.7333L9.65331 13.4133C10.007 13.0778 10.4758 12.8908 10.9633 12.8908C11.4508 12.8908 11.9197 13.0778 12.2733 13.4133L13.9266 15.0733L13.928 15.072L14.3933 14.6067C14.7462 14.2696 15.2154 14.0816 15.7033 14.0816C16.1913 14.0816 16.6604 14.2696 17.0133 14.6067L19.3333 16.92V12.6667C19.3333 12.8435 19.2631 13.013 19.1381 13.1381C19.013 13.2631 18.8435 13.3333 18.6666 13.3333Z" fill={props.fill} />
  </svg>

);
export default ReferenceFileSelectorIcon;
