import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { useEffect, useState } from "react";
import {
  toggleContentWizard,
  toggleDialog,
  toggleLoading,
} from "../../../store/appSlice";
import { setNewContent } from "../../../store/contentSlice";
import SvgGenerateSimilarWhiteIcon from "../../../assets/IconComponents/GenerateSimilarWhiteBg";
import {
  ProductImageRequest,
  useSaveProductContentMutation,
} from "../../../services/CreativeApi";
import { useRemoveBackgroundMutation } from "../../../services/ImageopsApi";
import { instanceOfApiErrorWithCode } from "../../../services/Core/BaseApi";
import { PaymentErrorCode } from "../../../services/Error/PaymentErrorCode";
import ConfirmationPopupOpener from "../../../components/ConfirmationPopup/ConfirmationPopup";
import PlanEditPopupOpener from "../../PlanPage/PlanEditPopup";

function ControlButtons(props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);
  const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] =
    useState(false);
  const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
  const userSettings = useAppSelector((state) => state.userSettings);
  const [effectiveAt, setEffectiveAt] = useState("");

  const [
    removeBackground,
    { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress },
  ] = useRemoveBackgroundMutation();
  const [
    createContent,
    {
      isLoading: isContentCreationLoading,
      isSuccess: contentCreationSuccess,
      data: contentCreationData,
      error: createContentError,
    },
  ] = useSaveProductContentMutation();

  useEffect(() => {
    if (contentCreationSuccess && !props.isInSide) {
      dispatch(toggleContentWizard());
      navigate(
        "/app/loopdetails/" + contentCreationData?.id?.content_id ?? "",
        {
          state: { newLoopCreated: true, isPhotoshoot: true },
        }
      );
    } else if (props.setContentCreationDataID) {
      props.setContentCreationDataID(contentCreationData?.id?.content_id ?? "");
    }
  }, [contentCreationSuccess]);

  useEffect(() => {
    dispatch(toggleLoading(false));
    if (createContentError && instanceOfApiErrorWithCode(createContentError)) {
      const errorData = createContentError.data;
      if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
        setShowUpgradePlanDialog(true);
      } else {
        console.log(errorData.message);
      }
    }
  }, [createContentError]);

  useEffect(() => {
    if (backgroundRemovedImage?.backgroundRemovedFile?.id) {
      dispatch(
        setNewContent({
          ...newContent,
          productFileId: backgroundRemovedImage?.backgroundRemovedFile?.id,
        })
      );
      setTimeout(() => {
        createLoop(backgroundRemovedImage?.backgroundRemovedFile?.id);
      }, 2000);
    }
  }, [backgroundRemovedImage]);

  useEffect(() => {
    const toggle = isContentCreationLoading || backgroundRemovalInProgress;
    if (!toggle) {
      setTimeout(() => {
        toggleLoading(false);
      }, 1000);
    }
    else {
      dispatch(
        toggleLoading(toggle)
      );
    }

  }, [isContentCreationLoading, backgroundRemovalInProgress]);

  const createLoop = (newProductId: string | undefined) => {
    var payload = {
      include_texts: true,
      referred_file_id:
        newContent?.logoFileId !== undefined && newContent?.logoFileId !== ""
          ? newContent.logoFileId
          : undefined,
      product_file_id: newProductId
        ? newProductId
        : newContent?.productFileId !== undefined &&
          newContent?.productFileId !== ""
          ? newContent.productFileId
          : undefined,
      brief: newContent?.contentAbout ?? "",
      ratio: newContent?.mediumOption?.ratio ?? undefined,
      width: newContent?.mediumOption?.width ?? undefined,
      height: newContent?.mediumOption?.height ?? undefined,
      models: [{ name: "ANY", count: props.numberOfImages ?? 4 }],
    } as ProductImageRequest;

    if (
      newProductId ||
      (newContent?.productFileId ?? "") === (props.lastRemovedBg ?? "")
    ) {
      createContent({
        productImageRequest: payload,
      });
    } else {
      removeBackground({
        removeBackgroundRequest: {
          imageFileId: newContent?.productFileId ?? "",
        },
      });
    }
  };

  return (
    <>
      {showPlanUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Your Plan Has Been Updated Successfully!"}
          subHeading={
            effectiveAt == "IMMEDIATELY"
              ? "Your plan has been successfully updated. Adjusted features are now in effect."
              : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."
          }
          buttonText={"Continue"}
          onClose={() => {
            setShowPlanUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      {isUpgradePlanPopupOpen && (
        <PlanEditPopupOpener
          onClosed={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
          currentPlanId={userSettings?.subscription?.plan_id}
          paymentMethod={userSettings?.payment_method}
          onPlanUpdateSucceeded={(effectiveAt?: string) => {
            setEffectiveAt(effectiveAt ?? "");
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
            setShowPlanUpdateSuccessPopup(true);
          }}
          onUpdatePaymentMethod={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      {showUpgradePlanDialog && (
        <ConfirmationPopupOpener
          heading={"Need More Credits?"}
          subHeading={
            "Upgrade your plan for more credits and never hit a roadblock in your workflow.."
          }
          buttonText={"View Plans"}
          onClose={function (): void {
            setShowUpgradePlanDialog(false);
            dispatch(toggleDialog(false));
            setIsUpgradePlanPopupOpen(true);
          }}
        />
      )}
      <div
        className={`w-full h-[85px] py-[15px] ${props.isInSide ? "" : "shadow bg-white"
          } justify-between items-center flex shrink-0`}
      >
        <div className="w-[52px] self-stretch px-2.5 py-2 opacity-0 bg-white rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex">
          <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
            Back
          </div>
        </div>
        <div
          className={`w-[200px] self-stretch ${props.isInSide ? "justify-end" : "justify-start"
            } items-center gap-2.5 flex`}
        >
          {!props.isInSide && (
            <PrimaryButton
              title={"Cancel"}
              noFill
              className="w-[72px] h-11 mr-2"
              onClick={function (): void {
                dispatch(setNewContent(undefined));
                dispatch(toggleContentWizard());
              }}
            />
          )}
          <PrimaryButton
            isDisabled={!newContent?.productFileId}
            icon={props.isInSide ? <SvgGenerateSimilarWhiteIcon /> : undefined}
            title={"Generate"}
            className={props.isInSide ? "w-[120px] h-9" : "w-[101px] h-11 mr-2"}
            onClick={function (): void {
              createLoop(undefined);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ControlButtons;
