import { FunctionComponent, useEffect, useState } from "react";
import {
  LoopChannels,
  LoopDetailChannel,
} from "../LoopDetailsHeader/Channel/LoopDetailChannels";
import { Liked, Like } from "../../../assets/IconComponents";
import IconButton from "../../../components/IconButton";
import { Delete } from "../../../assets/IconComponents";
import { t } from "i18next";
import { Revision, useFavoriteMutation } from "../../../services/RevisionApi";
import { ShimmerText, ShimmerTitle } from "react-shimmer-effects";
import { Content } from "../../../services/ContentApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import PrimaryButton from "../../../components/PrimaryButton";
import {
  setDialog,
  toggleDialog,
  toggleLoading,
} from "../../../store/appSlice";
import { useLazyDownloadQuery } from "../../../services/ImageApi.old/ImageApi";
import { ContentSize, SizeConstants } from "../../../constants/ContentSize";
import ImageContentDetailPopup from "../../../components/ImageContentDetailPopup";
import GenerateSimilarFilterPopup from "../../../components/GenerateSimilarFilterPopup";
import { IGenericSelectorData } from "../../../components/GenericSelector/GenericSelector";
import { useSaveRevisionMutation } from "../../../services/CommunityContentApi";
import SvgDownload from "../../../assets/IconComponents/Download";
import ReactPlayer from "react-player";
import { useLazyDownloadOriginalQuery } from "../../../services/FileApi";

interface ContentGroupVideoItemProps {
  content: Content;
  revision: Revision;
  generateSimilar: (revision: Revision, optionalArg?: any) => void;
  deleteItem: () => void;
  updatePage: () => void;
  incrementDownloadedCount: () => void;
  imageContentVisible: boolean;
  isInSide?: boolean;
  setSelectedImageItems?: (param: any) => void;
  selectedImageItems?: any[];
}

const ContentGroupVideoItem: FunctionComponent<ContentGroupVideoItemProps> = (
  props: ContentGroupVideoItemProps
) => {
  const [downloadImage, { data: imageFileContent }] = useLazyDownloadQuery();

  const [showContentDetail, setShowContentDetail] = useState(false);
  const [showContentImage, setShowContentImage] = useState(false);
  const [showGenerateSimilarFilters, setShowGenerateSimilarFilters] =
    useState(false);
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [isFavori, setFavori] = useState(false);
  const [isCommunityShared, setCommunityShared] = useState(false);
  const [contentSize, setContentSize] = useState<ContentSize>(ContentSize.post);
  const [favRev, { isSuccess: favSuccess }] = useFavoriteMutation();

  const isContentLoading = useAppSelector((k) => k.content.isContentLoading);
  const activeAIModels = useAppSelector((k) => k.persisted.activeAIModels);
  const dispatch = useAppDispatch();
  const [selectedKeptElements, setSelectedKeptElements] = useState<
    IGenericSelectorData[]
  >([]);
  const [elementsToKeep, setElementsToKeep] = useState<IGenericSelectorData[]>(
    []
  );
  const [shareRev] = useSaveRevisionMutation();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [videoType, setVideoType] = useState<"Gif" | "Video">("Video");
  const [
    downloadOriginalImage,
    { data: originalImageFileContent, isSuccess: originalFileDownloaded },
  ] = useLazyDownloadOriginalQuery();

  useEffect(() => {
    if (favSuccess) props.updatePage();
  }, [favSuccess]);

  useEffect(() => {
    if (props.revision) {
      setFavori(props.revision.is_favorite ?? false);
    }
  }, [props.revision]);

  useEffect(() => {
    if ((props.revision?.generated_content ?? "") === "") {
      console.error("no image id to download");
      return;
    }

    props.incrementDownloadedCount();
    const videoId = props.revision?.generated_content?.split("/").pop() ?? "";
    downloadOriginalImage({ id: videoId })
      .unwrap()
      .then(async () => {
        props.incrementDownloadedCount();
      });
  }, [props.revision?.generated_content]);

  useEffect(() => {
    setShowContentImage(props.imageContentVisible);
  }, [props.imageContentVisible]);

  useEffect(() => {
    if (showContentImage) {
      dispatch(toggleLoading(false));
    }
  }, [showContentImage]);

  useEffect(() => {
    if (
      props.selectedImageItems?.filter(
        (img) => img.generated_content === props.revision.generated_content
      ).length
    ) {
      props.selectedImageItems?.forEach((img, i) => {
        if (img.generated_content === props.revision.generated_content) {
          setSelectedIndex(i);
        }
      });
    } else {
      setSelectedIndex(-1);
    }
  }, [props.selectedImageItems]);

  const getContentSizeFromContent = (content: Content) => {
    let contentSize = ContentSize.post;
    const width = content.image_width ?? 1080;
    const height = content.image_height ?? 1080;
    if (width === 1200 && height == 628) {
      contentSize = ContentSize.landscape;
    } else if (width == 1080 && height == 1920) {
      contentSize = ContentSize.story;
    }
    return contentSize;
  };

  const getVideoTypeFromContent = (content: Content) => {
    const videoContent = JSON.parse(content?.videoContent ?? "{}");
    console.log("videoContent", videoContent);
    setVideoType(videoContent.video_type);
  };

  useEffect(() => {
    const newElements: IGenericSelectorData[] =
      (props.content.platform ?? "") !== "Product"
        ? [
            {
              id: "heading",
              title: "Keep Heading",
            },
            ...((props.content.image_height ?? 1080) > 50
              ? [
                  {
                    id: "subheading",
                    title: "Keep Subheading",
                  },
                ]
              : []),
            ...(props.content.cta || (props.content.image_height ?? 1080) <= 500
              ? [
                  {
                    id: "cta",
                    title: "Keep CTA",
                  },
                ]
              : []),
            {
              id: "imageai",
              title: "Keep AI Generated Image",
            },
          ]
        : [
            {
              id: "punchy",
              title: "Keep Punchy Message",
            },
            ...(props.revision.ctatext
              ? [
                  {
                    id: "burst",
                    title: "Keep Burst Message",
                  },
                ]
              : []),
            {
              id: "background",
              title: "Keep Background",
            },
          ];

    setElementsToKeep(newElements);
    const contentSize = getContentSizeFromContent(props.content);
    setContentSize(contentSize);
    getVideoTypeFromContent(props.content);
  }, [props.content, props.revision]);

  const showSelectOneModelDialogue = () => {
    dispatch(
      setDialog({
        actions: (
          <>
            <PrimaryButton
              className="h-12 w-20 ml-2"
              title={t("ok")}
              onClick={() => {
                dispatch(toggleDialog(false));
              }}
            />
          </>
        ),
        title: t("generate-similar"),
        message: t("you-should-at-least-select-one-ai-model"),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );
    dispatch(toggleDialog(true));
  };

  const downloadImageToLocal = () => {
    const link = document.createElement("a");

    const mimeType =
      videoType === "Gif" ? "data:image/gif;base64," : "data:video/mp4;base64,";
    link.href = mimeType + originalImageFileContent;
    link.download =
      (props.content.name ?? "") +
      "_" +
      (props.revision?.id?.revision_id ?? "") +
      (videoType === "Gif" ? ".gif" : ".mp4");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sizeValues = SizeConstants[contentSize];
  return (
    <>
      <ImageContentDetailPopup
        editingEnabled={editingEnabled}
        fullScreen
        closable
        show={showContentDetail}
        onClose={() => {
          setShowContentDetail(false);
          setEditingEnabled(false);
        }}
        className="!-mt-20 z-50"
        content={props.content}
        revision={props.revision}
        generateSimilar={() => {
          if (
            activeAIModels.filter((k) => k !== "ERROR").length === 0 &&
            props.isInSide != true
          ) {
            showSelectOneModelDialogue();
            return;
          }
          setShowContentDetail(false);
          if (
            (props.content?.platform ?? "") === "Product" &&
            !props.revision.headerText &&
            !props.revision.ctatext
          ) {
            props.generateSimilar(props.revision, []);
          } else {
            if (props.content.creative && props.content.creative.length > 0) {
              props.generateSimilar(props.revision);
            } else {
              setShowGenerateSimilarFilters(true);
            }
          }
        }}
        updatePage={props.updatePage}
        deleteRevisionItem={props.deleteItem}
        imageContent={imageFileContent}
      />
      <GenerateSimilarFilterPopup
        fullScreen={true}
        closable
        show={showGenerateSimilarFilters}
        onClose={() => {
          setShowContentDetail(false);
          setShowGenerateSimilarFilters(false);
        }}
        revision={props.revision}
        className="!-mt-20 z-50"
        itemsSelected={(itemsSelected) => {
          if (
            activeAIModels.filter((k) => k !== "ERROR").length === 0 &&
            props.isInSide != true
          ) {
            showSelectOneModelDialogue();
            return;
          }
          const filterItems = itemsSelected.map((item) => item.id);
          setSelectedKeptElements(itemsSelected);
          props.generateSimilar(props.revision, filterItems);
        }}
        imageContent={imageFileContent}
        items={elementsToKeep}
      />
      <div
        className={`flex flex-col pr-[10px] pl-[10px] border-1 border-border bg-white rounded-[10px]  ${
          props.revision.type !== "loading" && showContentImage
            ? "hover:border-red  cursor-pointer "
            : ""
        } ${
          contentSize === ContentSize.story ? "justify-center items-center" : ""
        } ${props.isInSide && selectedIndex !== -1 ? " border-red" : ""}`}
      >
        {(props.revision.type === "loading" || !showContentImage) && (
          <div className="py-2">
            <div>
              <p className="text-H6 text-black flex justify-between flex-row">
                <ShimmerTitle line={1} variant="secondary" className="w-36" />
                <ShimmerTitle
                  line={1}
                  variant="secondary"
                  className="w-[30px]"
                />
              </p>
            </div>
            <p className="text-BodySmall text-black mt-3">
              <ShimmerText line={8} gap={10} />
            </p>
            <p className="text-H6 text-black flex justify-between flex-row">
              <ShimmerTitle line={1} variant="secondary" className="w-20" />
              <div className="flex flex-row">
                <ShimmerTitle line={1} variant="secondary" className="w-11" />
                <ShimmerTitle
                  line={1}
                  variant="secondary"
                  className="w-11 ml-1"
                />
                <ShimmerTitle
                  line={1}
                  variant="secondary"
                  className="w-11 ml-1"
                />
              </div>
            </p>
          </div>
        )}
        {props.revision.type !== "loading" && showContentImage && (
          <>
            <div className="flex flex-row w-full items-center justify-between mt-2">
              <div>
                {props.isInSide && selectedIndex !== -1 && (
                  <div
                    className={
                      "w-[25px] h-[25px] rounded-[50%] bg-red text-white text-center p-[1px] m-1"
                    }
                  >
                    {selectedIndex + 1}
                  </div>
                )}
              </div>
              <div className="flex flex-row">
                {props.revision.original && (
                  <p className="text-BodySmall text-gray">Original</p>
                )}
                {!props.isInSide && (
                  <IconButton
                    className="hover:w-20 ml-[15px]"
                    title={isFavori ? t("unlike") : t("like")}
                    color={isFavori ? "red" : "gray"}
                    icon={isFavori ? Liked : Like}
                    onClick={(e) => {
                      if (!isContentLoading && !props.content.read_only) {
                        e.stopPropagation();
                        favRev({
                          revisionId: props.revision.id?.revision_id ?? "",
                          isFavorite: !isFavori,
                        });
                        setFavori(!isFavori);
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={`relative mt-${sizeValues.marginTop} rounded-[12px] pr-[10px] pl-[10px]`}
              style={{
                maxWidth: contentSize === ContentSize.story ? "400px" : "none",
                minWidth: contentSize === ContentSize.story ? "380px" : "none",
              }}
            >
              {true && (
                <>
                  {contentSize === ContentSize.story ? (
                    <div
                      className="w-full h-full overflow-hidden flex justify-center items-center"
                      style={{
                        height: `${sizeValues.renderedHeight}px`,
                      }}
                    >
                      {videoType === "Video" && (
                        <ReactPlayer
                          url={props.revision.generated_content}
                          width="100%"
                          height="100%"
                          controls={true}
                          pip={true}
                          // config={
                          //   {
                          //     playerVars: {
                          //       autoplay: 0,
                          //       loop: 1,
                          //       controls: 1,
                          //       color: "white",
                          //       modestbranding: 0,
                          //       rel: 0,
                          //     },
                          //   } as Config
                          // }
                        />
                      )}
                      {videoType === "Gif" && (
                        <img
                          src={props.revision.generated_content}
                          alt="Scaled Image"
                          style={{
                            objectFit: "contain",
                            width: "60%", // Adjust the scaling as needed
                            height: "auto",
                            borderRadius: "15px",
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className="w-full h-full flex justify-center items-center"
                      style={{
                        height: `${sizeValues.renderedHeight}px`,
                        borderRadius: "12px",
                      }}
                    >
                      {videoType === "Video" && (
                        <ReactPlayer
                          url={props.revision.generated_content}
                          width="100%"
                          height="100%"
                          controls={true}
                          pip={true}
                        />
                      )}
                      {videoType === "Gif" && (
                        <img
                          src={props.revision.generated_content}
                          alt="Scaled Image"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                            borderRadius: "12px",
                          }}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {!props.content.read_only && (
              <div className="w-full flex justify-between mt-2 mb-3">
                <div className="flex flex-row items-center "></div>
                <div className="flex flex-row items-center mr-[10px]">
                  <IconButton
                    className="hover:w-[100px] ml-[10px]"
                    title={t("download")}
                    icon={SvgDownload}
                    onClick={(e) => {
                      if (!isContentLoading) {
                        e.stopPropagation();
                        downloadImageToLocal();
                      }
                    }}
                  />
                  {!props.isInSide && (
                    <IconButton
                      className="hover:w-20 ml-[10px]"
                      title={t("delete")}
                      icon={Delete}
                      onClick={(e) => {
                        if (!isContentLoading) {
                          e.stopPropagation();
                          props.deleteItem();
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContentGroupVideoItem;
