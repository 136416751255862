import { FunctionComponent } from "react";
import { Radio } from "antd";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { VideoLength } from "../../../assets/IconComponents";
import { setNewContent } from "../../../store/contentSlice";
import { INewContent } from "../../../models/NewContent";
interface VideoLengthSelectorProps {
  title?: string;
  className?: string;
  isVertical?: boolean;
  briefMode?: boolean;
  briefContent?: INewContent;
  videoLengthSelectionUpdated?: (value: number) => void;
}

const VideoLengthSelector: FunctionComponent<VideoLengthSelectorProps> = (
  props: VideoLengthSelectorProps
) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);

  const radioStyle = {
    width: `${!props.briefMode ? "130px" : "70px"}`,
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex",
  };

  return (
    <div
      className={`self-stretch justify-start items-start gap-3 flex flex-row ${props.className}`}
    >
      <div
        className={`w-[240px] h-7 justify-start items-center gap-2.5 flex ${
          !props.briefMode ? `shrink-0` : ``
        }`}
      >
        <VideoLength fill={"#FF5555"} />
        <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
          {props.title ?? "Video Length"}
        </div>
      </div>

      <div
        className={`flex flex-row ${
          props.isVertical ? "w-full" : "w-2/3"
        } justify-end`}
      >
        <Radio.Group
          size={"large"}
          optionType="button"
          defaultValue={`${
            props.briefContent ? props.briefContent.video_length : "5"
          }`}
          onChange={(e) => {
            if (!props.briefContent) {
              dispatch(
                setNewContent({
                  ...newContent,
                  video_length: e.target.value as number,
                } as INewContent)
              );
            } else {
              props.videoLengthSelectionUpdated?.(e.target.value as number);
            }
          }}
        >
          <Radio.Button value="5" style={radioStyle}>
            5sc
          </Radio.Button>
          <Radio.Button value="10" style={radioStyle}>
            10sc
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default VideoLengthSelector;
