import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { ContentWizardView } from "../../models/ContentWizardView";
import ContentDetails from "./ContentDetails/ContentDetails";
import ImageLibrary from "./ImageLibrary/ImageLibrary";
import { FileProps, useLazyFindById4Query } from "../../services/BrandApi";
import CreateAudience from "./CreateAudience/CreateAudience";
import CreativeDetails from "./CreativeDetails/CreativeDetails";
import { AudienceResponse } from "../../services/AudiencetApi";
import { setNewContent } from "../../store/contentSlice";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard } from "../../store/appSlice";
import { clickOutside } from "../../helpers/ClickOutside";
import InspirationsLibrary from "./InspirationsLibrary/InspirationsLibrary";
import TechnicalDocumentsLibrary from "./TechnicalDocumentsLibrary/TechnicalDocumentLibrary";
import { Drawer } from "antd";
import ProductPhotoshootDetails from "./ProductPhotshootDetails/ProductPhotoshootDetails";
import VideoGenerationDetails from "./VideoGeneration/VideoGenerationDetails";

function ContentWizard() {
  const newContent = useAppSelector((k) => k.content.newContent);
  const [logoSelectionList, setLogoSelectionList] = useState<FileProps[]>([]);
  const [productSelectionList, setProductSelectionList] = useState<FileProps[]>(
    []
  );
  const [referenceFilesList, setReferenceFilesList] = useState<FileProps[]>([]);
  const [getBrand, { data: brand, isLoading: brandLoading }] =
    useLazyFindById4Query();
  const container = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [editingAudience, setEditingAudience] = useState<AudienceResponse>();
  const [recentlyAddedAudience, setRecentlyAddedAudience] = useState<
    AudienceResponse[]
  >([]);
  const contentWizardActive = useAppSelector(
    (state) => state.app.contentWizardActive
  );

  useEffect(() => {
    if (newContent) {
      getBrand({ id: newContent?.brand?.id ?? "" });
    }
  }, [newContent]);

  useEffect(() => {
    if (
      brand?.logo_file_ids &&
      logoSelectionList.length === 0 &&
      (newContent?.wizardView ?? ContentWizardView.contentDetails) !=
        ContentWizardView.aiProductPhotoshoot
    ) {
      setLogoSelectionList(brand.logo_file_ids);
    }
  }, [brand]);

  useEffect(() => {
    if (brand?.product_file_ids && productSelectionList.length === 0) {
      setProductSelectionList(brand.product_file_ids);
    }
  }, [brand]);

  useEffect(() => {
    if (
      brand?.reference_files &&
      referenceFilesList.length === 0 &&
      (newContent?.wizardView ?? ContentWizardView.contentDetails) !=
        ContentWizardView.aiProductPhotoshoot
    ) {
      setReferenceFilesList(brand.reference_files);
    }
  }, [brand]);

  useEffect(() => {
    if (container.current) {
      return clickOutside(container.current, () => {
        dispatch(setNewContent({} as INewContent));
        dispatch(toggleContentWizard());
      });
    }
  }, [container.current]);

  return (
    <div>
      <Drawer
        headerStyle={{ display: "none" }}
        extra={false}
        open={contentWizardActive}
        width="840"
        bodyStyle={{ padding: 0 }}
        onClose={() => {
          dispatch(setNewContent({} as INewContent));
          dispatch(toggleContentWizard());
        }}
      >
        {newContent?.wizardView == ContentWizardView.videoGeneration && (
          <VideoGenerationDetails
            logoSelectionList={logoSelectionList}
            productSelectionList={productSelectionList}
          />
        )}
        {newContent?.wizardView == ContentWizardView.aiProductPhotoshoot && (
          <ProductPhotoshootDetails
            logoSelectionList={logoSelectionList}
            productSelectionList={productSelectionList}
          />
        )}
        {newContent?.wizardView == ContentWizardView.contentDetails &&
          newContent?.isCreative === false && (
            <ContentDetails
              logoSelectionList={logoSelectionList}
              productSelectionList={productSelectionList}
              referenceFileSelectionList={referenceFilesList}
              onAudienceEdit={(data: AudienceResponse) => {
                setEditingAudience(data);
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.audience,
                  } as INewContent)
                );
              }}
              onAudienceCreate={() => {
                setEditingAudience(undefined);
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.audience,
                  } as INewContent)
                );
              }}
              recentlyAddedAudience={recentlyAddedAudience}
            />
          )}
        {newContent?.wizardView == ContentWizardView.contentDetails &&
          newContent?.isCreative === true && (
            <CreativeDetails
              logoSelectionList={logoSelectionList}
              productSelectionList={productSelectionList}
              onAudienceEdit={(data: AudienceResponse) => {
                setEditingAudience(data);
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.audience,
                  } as INewContent)
                );
              }}
              onAudienceCreate={() => {
                setEditingAudience(undefined);
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: ContentWizardView.audience,
                  } as INewContent)
                );
              }}
              recentlyAddedAudience={recentlyAddedAudience}
            />
          )}
        {newContent?.wizardView == ContentWizardView.audience && (
          <CreateAudience
            audience={editingAudience}
            onAudienceSaved={(data: AudienceResponse) => {
              let recentAudienceCopy = [
                data,
                ...recentlyAddedAudience.filter(
                  (a) => a.audience_id !== data.audience_id
                ),
              ];
              setRecentlyAddedAudience(recentAudienceCopy);
              dispatch(
                setNewContent({
                  ...newContent,
                  wizardView: ContentWizardView.contentDetails,
                  audienceId: data.audience_id,
                })
              );
            }}
          />
        )}
        {newContent?.wizardView == ContentWizardView.productLibrary && (
          <ImageLibrary
            mode={"product"}
            selectionList={brand?.product_file_ids ?? []}
            onImagePicksUpdated={(imagePicks: FileProps[]) => {
              setProductSelectionList(imagePicks);
            }}
          />
        )}
        {newContent?.wizardView ==
          ContentWizardView.videoReferenceImageLibrary && (
          <ImageLibrary
            mode={"video"}
            selectionList={brand?.product_file_ids ?? []}
            onImagePicksUpdated={(imagePicks: FileProps[]) => {
              setProductSelectionList(imagePicks);
            }}
          />
        )}
        {newContent?.wizardView ==
          ContentWizardView.creativeReferredImageLibrary && (
          <ImageLibrary
            mode={"referred_image"}
            selectionList={brand?.product_file_ids ?? []}
            onImagePicksUpdated={(imagePicks: FileProps[]) => {
              setProductSelectionList(imagePicks);
            }}
          />
        )}
        {newContent?.wizardView ==
          ContentWizardView.aiProductPhotoshootImageLibrary && (
          <ImageLibrary
            mode={"product_photoshoot"}
            selectionList={brand?.product_file_ids ?? []}
            onImagePicksUpdated={(imagePicks: FileProps[]) => {
              setProductSelectionList(imagePicks);
            }}
          />
        )}
        {newContent?.wizardView == ContentWizardView.logoLibrary && (
          <ImageLibrary
            mode={"logo"}
            selectionList={brand?.logo_file_ids ?? []}
            onImagePicksUpdated={(imagePicks: FileProps[]) => {
              setLogoSelectionList(imagePicks);
            }}
          />
        )}
        {newContent?.wizardView == ContentWizardView.inspoLibrary && (
          <InspirationsLibrary selectionList={brand?.logo_file_ids ?? []} />
        )}
        {newContent?.wizardView ==
          ContentWizardView.technicalDocumentsLibrary && (
          <TechnicalDocumentsLibrary
            selectionList={brand?.technical_links ?? []}
            onReferenceFilesUpdated={(referenceFiles: FileProps[]) => {
              setReferenceFilesList(referenceFiles);
            }}
          />
        )}
      </Drawer>
    </div>
  );
}

export default ContentWizard;
